const authModule = {
  namespaced: true,
  state: {
    debug: "",
    loading: {
      isLoading: false,
      text: "",
    },
    isLoading: false,
    alert: {
      isAlert: false,
      isIcon: false,
      isAction: false,
      isPersistent: false,
      alertBackground: "white",
      iconName: "",
      alertContent: "",
      contentColor: "text-black",
      isRedirect: false,
    },
    isClickCloseBtn: false,
  },
  mutations: {
    SET_DEBUG_DATA(state, debugData) {
      state.debug = debugData;
    },

    SET_ISLOADING_DATA(state, isLoadingData) {
      state.loading.isLoading = isLoadingData.isLoading;
      state.loading.text = isLoadingData.text;
    },

    SET_ALERT_DATA(state, isAlertData) {
      state.alert.isAlert = isAlertData.isAlert;
      state.alert.isIcon = isAlertData.isIcon;
      state.alert.isAction = isAlertData.isAction;
      state.alert.isPersistent = isAlertData.isPersistent;
      state.alert.alertContent = isAlertData.alertContent;
      state.alert.contentColor = isAlertData.contentColor;
      state.alert.iconName = isAlertData.iconName;
      state.alert.alertBackground = isAlertData.alertBackground;
      state.alert.isRedirect = isAlertData.isRedirect;
    },

    SET_ISCLICKCLOSEBTN_DATA(state, isClickCloseBtnData) {
      state.isClickCloseBtn = isClickCloseBtnData;
    },
  },
  actions: {
    setDebug(context, data) {
      context.commit("SET_DEBUG_DATA", data);
    },

    setIsloading(context, data) {
      context.commit("SET_ISLOADING_DATA", data);
    },

    setAlert(context, data) {
      context.commit("SET_ALERT_DATA", data);
    },

    setIsClickCloseBtn(context, data) {
      context.commit("SET_ISCLICKCLOSEBTN_DATA", data);
    },
  },
};

export default authModule;
