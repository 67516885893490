import { reactive } from "vue";
import { utilily } from "@/utilities/utility";
import { useRouter } from "vue-router";

export function useLogin() {
  const router = useRouter();
  const { setHeight } = utilily();
  const state = reactive({
    OtpText: "",
  });

  const handleLoginSubmit = () => {
    router.replace({ name: "homePage" });
  };

  return { state, setHeight, handleLoginSubmit };
}
