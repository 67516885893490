import axios from "axios";

const BASE_API_URL = process.env.VUE_APP_API_URL
  ? process.env.VUE_APP_API_URL
  : "https://office.chatbot.softbot.space";
console.log("url", BASE_API_URL);
const http = axios.create({
  baseURL: BASE_API_URL,
  withCredentials: true,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export async function request(method, url, data, token = null) {
  var response;
  var headers = {};
  if (token) {
    const header_auth = {
      Authorization: "Bearer " + token,
    };

    headers = {
      ...header_auth,
    };
  }

  try {
    response = await http({
      method,
      url,
      data,
      headers,
    });
  } catch (error) {
    console.log("error ->>", error.response);
    if (error.response.status === 401) {
      console.log("ERROR 401");
    }
    return {
      status: "http_error",
      message: "HTTP Error " + error.response.status,
    };
  }
  // console.log(response);
  return response.data;
}

export async function requestImg(method, url, data, token = null) {
  var response;
  let headers = {};
  if (token) {
    const header_auth = {
      Authorization: "Bearer " + token,
      "Content-Type": "multipart/form-data",
    };

    headers = {
      ...header_auth,
    };
  }

  try {
    response = await http({
      method,
      url,
      data,
      headers,
    });
  } catch (error) {
    console.log("error ->>", error.response);
    if (error.response.status === 401) {
      console.log("ERROR 401");
    }
    return error;
  }
  // console.log(response);
  return response.data;
}
