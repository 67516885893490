export function utilily() {
  const setHeight = (offset) => {
    const height = offset ? `calc(100vh - ${offset}px)` : "100vh";
    document.documentElement.style.setProperty("--height", height);
    return { height };
  };

  const numberRule = (value) => {
    if (!value) {
      return "กรุณากรอกจำนวนเงิน";
    }

    const regex = /^\d+(\.\d{1,2})?$/;

    if (!regex.test(value)) {
      return "กรุณากรอกจำนวนเงินให้ถูกต้อง";
    }

    return true;
  };

  const dateRule = (value) => {
    if (!value) {
      return "กรุณากรอกวันที่";
    }

    const regex = /^\d{4}-\d{2}-\d{2}$/;

    if (!regex.test(value)) {
      return "กรุณากรอกวันที่และเวลาให้ถูกต้อง";
    }

    return true;
  };

  const timeRule = (value) => {
    if (!value) {
      return "กรุณากรอกเวลา";
    }

    const regex = /^\d{2}:\d{2}$/;

    if (!regex.test(value)) {
      return "กรุณากรอกวันที่และเวลาให้ถูกต้อง";
    }

    return true;
  };

  const selectRule = (value) => {
    if (
      typeof value !== "object" &&
      value !== "" &&
      value !== "กำลังโหลดข้อมูล" &&
      value !== "ไม่พบบัญชีธนาคารในระบบ"
    ) {
      return true;
    } else {
      return "";
    }
  };

  const fileRule = (value) => {
    if (!value) {
      return "กรุณาอัพโหลดรูป";
    }
    if (value.size > 2 * 1024 * 1024) {
      // 2 MB in bytes
      return "กรุณาใช้รูปขนาดไม่เกิน 2MB";
    }
    return true;
  };

  return { setHeight, numberRule, dateRule, timeRule, selectRule, fileRule };
}
