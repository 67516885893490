<template>
  <q-page class="q-gutter-y-md q-mx-md" :style-fn="setHeight">
    <div class="logo-sector home flex justify-center">
      <div class="login-logo"></div>
    </div>
    <div class="text-center font-semibold text-h6">เลือกเมนู</div>
    <div @click="handleClickDeposit" class="row home-item">
      <div class="col-3 deposit-icon"></div>
      <div class="col-9">
        <div class="font-medium text-h5">ฝากเงิน</div>
        <div class="font-regular text-grey-87">
          แจ้งโอนได้ด้วยตัวเองไม่ต้องรอแอดมิน
        </div>
      </div>
    </div>
    <div @click="handleClickWithdraw" class="row home-item">
      <div class="col-3 withdraw-icon"></div>
      <div class="col-9">
        <div class="font-medium text-h5">ถอนเงิน</div>
        <div class="font-regular text-grey-87">ถอนเงินด้วยระบบอัตโนมัติ</div>
      </div>
    </div>
    <div @click="handleClicBankList" class="row home-item">
      <div class="col-3 depositbank-icon"></div>
      <div class="col-9">
        <div class="font-medium text-h5">ดูบัญชีฝาก</div>
        <div class="font-regular text-grey-87">รายการบัญชีปลายทางในการโอน</div>
      </div>
    </div>
    <div @click="handleClickReport" class="row home-item">
      <div class="col-3 report-icon"></div>
      <div class="col-9">
        <div class="font-medium text-h5">รายงานการเงิน</div>
        <div class="font-regular text-grey-87">
          ตรวจดูสถานะของการเงินได้ตลอดเวลา
        </div>
      </div>
    </div>
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import { useHome } from "./composable/use-home";

export default {
  setup() {
    const {
      state,
      setHeight,
      handleClickDeposit,
      handleClickWithdraw,
      handleClicBankList,
      handleClickReport,
    } = useHome();

    return {
      ...toRefs(state),
      setHeight,
      handleClickDeposit,
      handleClickWithdraw,
      handleClicBankList,
      handleClickReport,
    };
  },
};
</script>

<style lang="scss" scoped></style>
