<template>
  <q-page class="q-mx-md" :style-fn="setHeight">
    <div class="logo-sector flex justify-center">
      <div class="login-logo"></div>
    </div>
    <div class="home-item font-semibold q-gutter-y-sm">
      <div class="text-center" style="font-size: 1.15rem">
        ไม่สามารถเข้าใช้งานได้
      </div>
      <div class="text-center font-medium">
        กรุณาติดต่อแอดมินเพื่อดำเนินการเชื่อมต่อให้
      </div>
      <q-separator class="q-my-lg" />
      <div class="text-center" style="font-size: 1.15rem">
        หรือใส่ Link Code เพื่อเชื่อมต่อ
      </div>
      <div>
        <q-input
          filled
          v-model="token"
          bg-color="grey-f3"
          color="black"
          label="ใส่ Link Code"
          class="font-regular"
        />
      </div>
      <div class="flex justify-end">
        <q-btn
          @click="handleSubmitToken(token !== '' ? true : false)"
          :color="token !== '' ? 'gd-green' : 'grey-95'"
          text-color="white"
          label="เชื่อมต่อ"
        />
      </div>
    </div>
  </q-page>
</template>

<script>
import { onMounted, reactive, toRefs } from "vue";
import { utilily } from "@/utilities/utility";
import { linkToken, login } from "@/services/auth-service";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  setup() {
    const { setHeight } = utilily();
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      token: "",
      debug: "",
    });

    onMounted(() => {
      state.debug = `access_token = ${localStorage.getItem(
        "ltoken"
      )}; web_code = ${localStorage.getItem("c")}`;
    });

    const handleSubmitToken = async (validate) => {
      if (validate === false) {
        return;
      }
      store.dispatch("authModule/setIsloading", {
        isLoading: true,
        text: "กำลังส่งข้อมูล",
      });
      const data = {
        token: state.token,
        line_id: localStorage.getItem("ltoken"),
        web_code: localStorage.getItem("c"),
      };
      const response = await linkToken(data);
      state.debug = response;
      store.dispatch("authModule/setIsloading", {
        isLoading: false,
        text: "",
      });
      if (response.status === "success") {
        loginLiff();
      } else if (response.status === "http_error") {
        store.dispatch("authModule/setAlert", {
          isAlert: true,
          isIcon: true,
          isAction: false,
          isPersistent: false,
          iconName: "cancel",
          alertContent: `<span class="font-medium text-h6 q-pl-sm">${response.message}</span>`,
          contentColor: "text-d-red",
          alertBackground: "bg-white",
          isRedirect: true,
        });
      } else {
        store.dispatch("authModule/setAlert", {
          isAlert: true,
          isIcon: response.data?.descHtml !== undefined ? false : true,
          isAction: false,
          isPersistent: false,
          iconName: "cancel",
          alertContent:
            response.data?.descHtml !== undefined
              ? `${response.data.descHtml}`
              : `<span class="font-medium text-d-red text-h6 q-pl-sm">${response.message}</span>`,
          contentColor: "text-d-red",
          alertBackground: "bg-white",
          isRedirect: false,
        });
      }
    };

    const loginLiff = async () => {
      const data = { access_token: localStorage.getItem("ltoken") };
      store.dispatch("authModule/setIsloading", {
        isLoading: true,
        text: "กำลังล็อกอินเข้าสู่ระบบ",
      });
      const response = await login(data);
      state.debug = response;
      store.dispatch("authModule/setIsloading", {
        isLoading: false,
        text: "",
      });
      if (response.status === "success") {
        localStorage.setItem("token", response.access_token);
        router.replace({ name: "homePage" });
      } else if (response.status === "http_error") {
        setTimeout(() => {
          store.dispatch("authModule/setAlert", {
            isAlert: true,
            isIcon: true,
            isAction: false,
            isPersistent: false,
            iconName: "cancel",
            alertContent: `<span class="font-medium text-h6 q-pl-sm">${response.message}</span>`,
            contentColor: "text-d-red",
            alertBackground: "bg-white",
            isRedirect: true,
          });
        }, 500);
      } else {
        setTimeout(() => {
          store.dispatch("authModule/setAlert", {
            isAlert: true,
            isIcon: response.data?.descHtml !== undefined ? false : true,
            isAction: false,
            isPersistent: false,
            iconName: "cancel",
            alertContent:
              response.data?.descHtml !== undefined
                ? `${response.data.descHtml}`
                : `<span class="font-medium text-d-red text-h6 q-pl-sm">${response.message}</span>`,
            contentColor: "text-d-red",
            alertBackground: "bg-white",
            isRedirect: false,
          });
        }, 500);
      }
    };

    return {
      ...toRefs(state),
      setHeight,
      handleSubmitToken,
    };
  },
};
</script>

<style lang="scss" scoped></style>
