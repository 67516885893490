import { createStore } from "vuex";
import authModule from "./auth/auth-module";
import transactionModule from "./transaction/transaction-module";

export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: { authModule, transactionModule },
});
