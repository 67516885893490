import { onMounted, reactive } from "vue";
import { utilily } from "@/utilities/utility";
import { useRouter } from "vue-router";
import { getTransactionList } from "../../../services/transaction-service";
import { useStore } from "vuex";

export function useReport() {
  const router = useRouter();
  const { setHeight } = utilily();
  const store = useStore();
  const state = reactive({
    tab: "deposit",
    depositLists: [],
    withdrawLists: [],
    depositUpdateTime: "",
    withdrawUpdateTime: "",
    isComplete: false,
    debug: "",
  });

  onMounted(() => {
    getDepositList();
  });

  const getDepositList = async () => {
    store.dispatch("authModule/setIsloading", {
      isLoading: true,
      text: "กำลังโหลดข้อมูล",
    });
    const data = `?type=200`;
    const response = await getTransactionList(data);
    state.debug = response;
    store.dispatch("authModule/setIsloading", {
      isLoading: false,
      text: "",
    });
    if (response.status === "success") {
      state.depositLists = response.data;
      state.depositUpdateTime = getCurrentTime();
    }
  };

  const getWithdrawList = async () => {
    store.dispatch("authModule/setIsloading", {
      isLoading: true,
      text: "กำลังโหลดข้อมูล",
    });
    const data = `?type=400`;
    const response = await getTransactionList(data);
    state.debug = response;
    store.dispatch("authModule/setIsloading", {
      isLoading: false,
      text: "",
    });
    if (response.status === "success") {
      state.withdrawLists = response.data;
      state.withdrawUpdateTime = getCurrentTime();
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = parseInt(date.getFullYear().toString().slice(-2)) + 43;
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const getCurrentTime = () => {
    const date = new Date();

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${seconds}`;
  };

  const handleClickRefresh = async () => {
    if (state.tab === "deposit") {
      await getDepositList();
      // state.isComplete = true;
    } else {
      await getWithdrawList();
      // state.isComplete = true;
    }
    // setTimeout(() => {
    //   state.isComplete = false;
    // }, 3000);
  };

  const handleClickBackHome = () => {
    router.push({ name: "homePage" });
  };

  const numberWithCommas = (number = null) => {
    if (!number) {
      return 0;
    }
    if (Number.isInteger(number)) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    } else {
      return number
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  return {
    state,
    setHeight,
    handleClickRefresh,
    handleClickBackHome,
    getDepositList,
    getWithdrawList,
    numberWithCommas,
    formatTimestamp,
  };
}
