<template>
  <q-page :style-fn="setHeight">
    <q-form
      @submit="handleSubmitWithdraw()"
      class="q-gutter-y-md q-mx-md font-regular"
    >
      <div class="q-pt-md">
        <q-select
          @update:model-value="handleUserChange"
          filled
          v-model="user"
          bg-color="white"
          color="grey"
          :options="userOptions"
          label="เลือก User"
          emit-value
          map-options
          :behavior="'menu'"
          :rules="[selectRule]"
        />
      </div>
      <div class="q-mt-none">
        <q-select
          filled
          v-model="bank"
          :bg-color="bankOptions.length <= 0 ? 'grey-95' : 'white'"
          color="grey"
          :options="bankOptions"
          label="ธนาคาร"
          emit-value
          map-options
          :disable="bankOptions.length <= 0 ? true : false"
          :behavior="'menu'"
          :rules="[selectRule]"
        >
          <template v-slot:selected-item="scope">
            <div class="row full-width">
              <div v-if="bankOptions.length <= 0" class="" style="width: 10%">
                <div
                  class="bank-icon full-height full-width"
                  :class="scope.opt.icon"
                ></div>
              </div>
              <div class="" style="width: 90%">{{ scope.opt.label }}</div>
            </div>
          </template>
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section avatar>
                <div
                  class="bank-icon full-height full-width"
                  :class="scope.opt.icon"
                ></div>
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-black">{{
                  scope.opt.label
                }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
      <div class="q-mt-none">
        <q-input
          filled
          v-model="amount"
          type="number"
          bg-color="white"
          color="black"
          label="ยอดเงิน"
          :rules="[numberRule]"
        />
      </div>
      <div class="row q-col-gutter-x-md font-medium q-mt-none">
        <div class="col-6">
          <q-btn
            @click="handleClickBack"
            class="full-width"
            color="white"
            text-color="black"
            label="กลับ"
          />
        </div>
        <div class="col-6">
          <q-btn
            type="submit"
            class="full-width"
            :color="
              user !== '' && bank !== '' && amount !== '' ? 'gd-red' : 'grey-95'
            "
            text-color="white"
            label="แจ้งถอนเงิน"
          />
        </div>
      </div>
    </q-form>
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import { useWithdraw } from "./composable/use-withdraw";
import { utilily } from "../../utilities/utility";

export default {
  setup() {
    const {
      state,
      setHeight,
      handleUserChange,
      handleClickBack,
      handleSubmitWithdraw,
    } = useWithdraw();

    const { numberRule, selectRule } = utilily();

    return {
      ...toRefs(state),
      setHeight,
      handleUserChange,
      handleClickBack,
      handleSubmitWithdraw,
      numberRule,
      selectRule,
    };
  },
};
</script>

<style lang="scss" scoped></style>
