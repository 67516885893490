<template>
  <q-page class="q-gutter-y-md q-mx-md" :style-fn="setHeight">
    <div class="logo-sector flex justify-center">
      <div class="login-logo"></div>
    </div>
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import { useLoading } from "./composable/use-loading";

export default {
  setup() {
    const { state, setHeight } = useLoading();

    return {
      ...toRefs(state),
      setHeight,
    };
  },
};
</script>

<style lang="scss" scoped></style>
