import { request } from "./api";

export function login(data) {
  const url = `/api/line/login`;
  return request("post", url, data);
}

export function linkToken(data) {
  const url = `api/linktoken`;
  return request("post", url, data);
}
