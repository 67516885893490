import { computed, onMounted, reactive, ref } from "vue";
import { utilily } from "@/utilities/utility";
import { getUser, getDepositList } from "../../../services/transaction-service";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export function useDepositBank() {
  const { setHeight } = utilily();
  const store = useStore();
  const router = useRouter();
  const input = ref(null);
  const sep = ref(null);
  const btn = ref(null);
  const state = reactive({
    user: "",
    userOptions: [],
    bankLists: [],
    userLists: computed(() => store.state.transactionModule.user),
    error: "",
    debug: "",
  });

  onMounted(async () => {
    state.error = "กำลังโหลดข้อมูล...";
    await getUserData();
    state.error = "";
  });

  const getUserData = async () => {
    const response = await getUser();
    state.debug = response;
    if (response.status === "success") {
      store.dispatch("transactionModule/setUser", response.data);
      state.userLists.forEach((element) => {
        state.userOptions.push({ label: element.user, value: element.id });
      });
      if (state.userOptions.length > 0) {
        getDepositListData(state.userOptions[0].value);
        state.user = state.userOptions[0].value;
      }
    } else {
      state.error = "ไม่พบ ID ในระบบ";
    }
  };

  const getDepositListData = async (id) => {
    state.bankLists = [];
    state.error = "กำลังโหลดข้อมูล...";
    const response = await getDepositList(id);
    state.debug = response;
    if (response.status === "success") {
      // state.bankLists = response.data.toBank;
      response.data.toBank.forEach((element, index) => {
        state.bankLists[index] = element;
        state.bankLists[index].isCopy = false;
      });
    } else {
      state.error = "ไม่มีรายการ</br>กรุณาติดต่อแอดมิน";
    }
  };

  const handleUserChange = (value) => {
    state.user = value;
    getDepositListData(value);
  };

  const setChatHeight = () => {
    const height = `calc(100% - ${
      input.value !== null ? input.value.clientHeight : 0
    }px - ${sep.value !== null ? sep.value.clientHeight : 0}px - ${
      btn.value !== null ? btn.value.clientHeight : 0
    }px - 64px)`;
    return height;
  };

  const handleClickCopyAcc = (text, index) => {
    const element = document.querySelector("#textCopy");
    element.value = text.replace(/-/g, "");
    element.select();
    document.execCommand("copy");
    state.bankLists[index].isCopy = true;
    setTimeout(() => {
      state.bankLists[index].isCopy = false;
    }, 3000);
  };

  const handleClickGoBack = () => {
    router.replace({ name: "homePage" });
  };

  return {
    state,
    input,
    sep,
    btn,
    setHeight,
    handleUserChange,
    getUserData,
    setChatHeight,
    handleClickCopyAcc,
    handleClickGoBack,
  };
}
