import { request, requestImg } from "./api";

export function getUser() {
  const url = `/api/line/customeruser`;
  return request("get", url, {}, localStorage.getItem("token"));
}

// export function getDepositList(data) {
//   const url = `/api/line/depositbank/${data}`;
//   return request("get", url, {}, localStorage.getItem("token"));
// }

export function getDepositList(data) {
  const url = `/api/line/depositdata/${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function getMemberBankList(data) {
  const url = `/api/line/listmemberbank/${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}

export function postTransaction(data) {
  const url = `/api/line/transactions`;
  return request("post", url, data, localStorage.getItem("token"));
}

export function uploadImage(data) {
  const formData = new FormData();
  formData.append("image", data);
  const url = `/api/line/uploadimage`;
  return requestImg("post", url, formData, localStorage.getItem("token"));
}

export function getTransactionList(data) {
  const url = `/api/line/transactions${data}`;
  return request("get", url, {}, localStorage.getItem("token"));
}
