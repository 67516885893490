import { onMounted, reactive } from "vue";
import { utilily } from "@/utilities/utility";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import liff from "@line/liff";
import { login } from "@/services/auth-service";

export function useLoading() {
  const { setHeight } = utilily();
  const router = useRouter();
  const store = useStore();
  const state = reactive({
    debug: "",
  });

  onMounted(() => {
    initLiff();
  });

  const checkQuery = () => {
    const queryString = window.location.search;
    state.debug = queryString;
    if (queryString !== null || queryString !== undefined) {
      const urlParams = new URLSearchParams(queryString);

      const ctoken = urlParams.get("c");
      localStorage.setItem("c", ctoken);

      const mode = urlParams.get("m");
      state.debug = "MODE : " + mode;

      if (mode === "home") {
        router.replace({ name: "homePage" });
      } else if (mode === "deposit") {
        router.replace({ name: "depositPage" });
      } else if (mode === "withdraw") {
        router.replace({ name: "withdrawPage" });
      } else if (mode === "report") {
        router.replace({ name: "reportPage" });
      }
    }
  };

  const initLiff = () => {
    store.dispatch("authModule/setIsloading", {
      isLoading: true,
      text: "กำลังโหลดข้อมูล",
    });
    const queryString = window.location.search;
    if (queryString !== null || queryString !== undefined) {
      const urlParams = new URLSearchParams(queryString);
      const liffId = urlParams.get("liff_id");
      liff
        .init({ liffId: liffId })
        .then(() => {
          getProfile();
          // Initialization succeeded
          //
        })
        .catch((error) => {
          // Initialization failed
          state.debug = error;
          store.dispatch("authModule/setIsloading", {
            isLoading: false,
            text: "",
          });
          setTimeout(() => {
            store.dispatch("authModule/setAlert", {
              isAlert: true,
              isIcon: true,
              isAction: false,
              isPersistent: false,
              iconName: "cancel",
              alertContent: `<span class="font-medium text-d-red text-h6 q-pl-sm">${error}</span>`,
              contentColor: "text-d-red",
              alertBackground: "bg-white",
              isRedirect: false,
            });
          }, 500);
          router.replace({ name: "errorPage" });
        });
    }
  };

  const getProfile = () => {
    state.access_token = liff.getAccessToken();
    state.debug = state.access_token;
    localStorage.setItem("ltoken", state.access_token);
    loginLiff();
  };

  const loginLiff = async () => {
    if (state.access_token === "") {
      return;
    }
    const data = { access_token: state.access_token };
    const response = await login(data);
    state.debug = response;
    store.dispatch("authModule/setIsloading", {
      isLoading: false,
      text: "",
    });
    if (response.status === "success") {
      localStorage.setItem("token", response.access_token);
      checkQuery();
    } else {
      router.replace({ name: "errorPage" });
    }
  };

  return { state, setHeight };
}
