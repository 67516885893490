import { computed, reactive, ref, watch } from "vue";
import { utilily } from "@/utilities/utility";
import {
  getUser,
  getDepositList,
  uploadImage,
  postTransaction,
} from "../../../services/transaction-service";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export function useDeposit() {
  const { setHeight } = utilily();
  const store = useStore();
  const router = useRouter();
  const state = reactive({
    user: "",
    userOptions: [],
    userBank: "",
    userBankOptions: [],
    bank: "",
    bankOptions: [
      // {
      //   label: "Test",
      //   value: "12",
      //   icon: "kbank",
      // },
      // {
      //   label: "Test2",
      //   value: "13",
      //   icon: "scb",
      // },
    ],
    amount: "",
    imageFile: [],
    date: new Date().toISOString().substr(0, 10),
    time: new Date().toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }),
    image_url: null,
    userLists: computed(() => store.state.transactionModule.user),
    isClickCloseBtn: computed(() => store.state.authModule.isClickCloseBtn),
    isSelectedMode: false,
    isNormalMode: true,
    debug: "",
    debug2: "",
  });

  const fileInput = ref(null);

  watch(
    () => state.isClickCloseBtn,
    (isClickCloseBtn) => {
      if (isClickCloseBtn === true) {
        state.isSelectedMode = false;
        state.user = "";
        state.bank = "";
        state.userBank = "";
        state.amount = "";
        state.imageFile = [];
        state.image_url = null;
        state.date = new Date().toISOString().substr(0, 10);
        state.time = new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
      }
    }
  );

  const handleClickDepositMode = async (isNormalMode) => {
    state.isSelectedMode = true;
    state.isNormalMode = isNormalMode;
    store.dispatch("authModule/setIsloading", {
      isLoading: true,
      text: "กำลังโหลดข้อมูล",
    });
    await getUserData();
    store.dispatch("authModule/setIsloading", {
      isLoading: false,
      text: "",
    });
  };

  const getUserData = async () => {
    const response = await getUser();
    state.debug = response;
    if (response.status === "success") {
      store.dispatch("transactionModule/setUser", response.data);
      state.userLists.forEach((element) => {
        state.userOptions.push({ label: element.user, value: element.id });
      });

      if (state.userOptions.length === 1) {
        getDepositListData(state.userOptions[0].value);
        state.user = state.userOptions[0].value;
      }
    } else if (response.status === "http_error") {
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: true,
        isAction: false,
        isPersistent: false,
        iconName: "cancel",
        alertContent: `<span class="font-medium text-h6 q-pl-sm">${response.message}</span>`,
        contentColor: "text-d-red",
        alertBackground: "bg-white",
        isRedirect: true,
      });
    } else {
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: response.data?.descHtml !== undefined ? false : true,
        isAction: false,
        isPersistent: false,
        iconName: "cancel",
        alertContent:
          response.data?.descHtml !== undefined
            ? `${response.data.descHtml}`
            : `<div class='q-pl-sm'><div class="font-medium text-h6">ไม่พบ ID ในระบบ</div><div>กรุณาติดต่อแอดมินเพื่อทำการแก้ไข</div></div>`,
        contentColor: "text-d-red",
        alertBackground: "bg-white",
        isRedirect: false,
      });
    }
  };

  const getDepositListData = async (id) => {
    state.userBank = "กำลังโหลดข้อมูล";
    state.bank = "กำลังโหลดข้อมูล";
    const response = await getDepositList(id);
    state.userBank = "";
    state.bank = "";
    state.debug = response;
    state.userBankOptions = [];
    state.bankOptions = [];
    if (response.status === "success") {
      response.data.fromBank.forEach((element) => {
        state.userBankOptions.push({
          label: element.bankName + " " + element.accNumber,
          icon: element.bankCode,
          value: element.id,
        });
      });
      response.data.toBank.forEach((element) => {
        state.bankOptions.push({
          label: element.bankName + " " + element.accNumber,
          icon: element.bankCode,
          value: element.id,
        });
      });
      if (state.userBankOptions.length === 1) {
        state.userBank = state.userBankOptions[0].value;
      }
      if (state.bankOptions.length === 1) {
        state.bank = state.bankOptions[0].value;
      }
    } else if (response.status === "http_error") {
      state.userBank = {
        label: "ไม่พบบัญชีธนาคารในระบบ",
        value: "",
      };
      state.bank = {
        label: "ไม่พบบัญชีธนาคารในระบบ",
        value: "",
      };
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: true,
        isAction: false,
        isPersistent: false,
        iconName: "cancel",
        alertContent: `<span class="font-medium text-h6 q-pl-sm">${response.message}</span>`,
        contentColor: "text-d-red",
        alertBackground: "bg-white",
        isRedirect: true,
      });
    } else {
      state.userBank = {
        label: "ไม่พบบัญชีธนาคารในระบบ",
        value: "",
      };
      state.bank = {
        label: "ไม่พบบัญชีธนาคารในระบบ",
        value: "",
      };
      state.debug = state.bankOptions;
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: response.data?.descHtml !== undefined ? false : true,
        isAction: false,
        isPersistent: false,
        iconName: "cancel",
        alertContent:
          response.data?.descHtml !== undefined
            ? `${response.data.descHtml}`
            : `<div class='q-pl-sm'><div class="font-medium text-h6">ไม่พบบัญชีธนาคารในระบบ</div><div>กรุณาติดต่อแอดมินเพื่อทำการแก้ไข</div></div>`,
        contentColor: "text-d-red",
        alertBackground: "bg-white",
        isRedirect: false,
      });
    }
  };

  const handleUserChange = (value) => {
    state.user = value;
    getDepositListData(value);
  };

  const handleChooseFile = async (value) => {
    if (value.size > 2 * 1024 * 1024) {
      state.imageFile = "";
      state.image_url = null;
      return;
    }
    console.log(value);
    const response = await uploadImage(value);
    if (response.status === "success") {
      state.image_url = response.data.image_url;
    } else if (response.status === "http_error") {
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: true,
        isAction: false,
        isPersistent: false,
        iconName: "cancel",
        alertContent: `<span class="font-medium text-h6 q-pl-sm">${response.message}</span>`,
        contentColor: "text-d-red",
        alertBackground: "bg-white",
        isRedirect: true,
      });
    } else {
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: response.data?.descHtml !== undefined ? false : true,
        isAction: false,
        isPersistent: false,
        iconName: "cancel",
        alertContent:
          response.data?.descHtml !== undefined
            ? `${response.data.descHtml}`
            : `<div class='q-pl-sm'><div class="font-medium text-h6">ไม่สามารถอัพโหลดรูปภาพได้</div><div>กรุณาติดต่อลองใหม่อีกครั้ง</div></div>`,
        contentColor: "text-d-red",
        alertBackground: "bg-white",
        isRedirect: false,
      });
    }
    state.debug = response;
  };

  const handleClickBack = () => {
    router.push({ name: "homePage" });
  };

  const handleSubmitDeposit = async (validate) => {
    if (validate === false) {
      return;
    }
    store.dispatch("authModule/setIsloading", {
      isLoading: true,
      text: "กำลังส่งข้อมูล",
    });
    const userById = state.userLists.find((obj) => obj.id === state.user);
    var data;
    if (state.isNormalMode === true) {
      data = {
        type: "200",
        image_url: state.image_url,
        user: userById.user,
        from_bank_acc: state.userBank,
        bank_acc: state.bank,
        amount: state.amount,
        transaction_time: Date.parse(state.date + " " + state.time) / 1000,
        note: "แจ้งโอน",
      };
    } else {
      data = {
        type: "200",
        image_url: state.image_url,
        user: userById.user,
        transaction_time: Math.floor(Date.now() / 1000),
        note: "แจ้งโอน",
      };
    }

    state.debug = data;
    const response = await postTransaction(data);
    state.debug2 = response;
    store.dispatch("authModule/setIsloading", {
      isLoading: false,
      text: "",
    });
    if (response.status === "success") {
      store.dispatch("authModule/setIsClickCloseBtn", false);
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: true,
        isAction: true,
        isPersistent: false,
        iconName: "check_circle",
        alertContent: `<span class="font-medium text-h6 q-pl-sm" style="font-size:1.5rem">แจ้งฝากเงินสำเร็จ</span>`,
        contentColor: "text-d-green",
        alertBackground: "bg-l-green",
        isRedirect: true,
      });
    } else if (response.status === "http_error") {
      store.dispatch("authModule/setIsClickCloseBtn", true);
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: true,
        isAction: false,
        isPersistent: false,
        iconName: "cancel",
        alertContent: `<span class="font-medium text-h6 q-pl-sm">${response.message}</span>`,
        contentColor: "text-d-red",
        alertBackground: "bg-white",
        isRedirect: true,
      });
    } else {
      store.dispatch("authModule/setIsClickCloseBtn", true);
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: response.data?.descHtml !== undefined ? false : true,
        isAction: false,
        isPersistent: false,
        iconName: "cancel",
        alertContent:
          response.data?.descHtml !== undefined
            ? `${response.data.descHtml}`
            : `<div class='q-pl-sm'><div class="font-medium text-h6">รายการไม่สำเร็จ</div><div>กรุณาลองใหม่อีกครั้งหรือติดต่อแอดมิน</div></div>`,
        contentColor: "text-d-red",
        alertBackground: "bg-white",
        isRedirect: true,
      });
    }
  };

  const optionsDate = (date) => {
    return date <= new Date().toISOString().substr(0, 10).replace(/-/g, "/");
  };

  const openFilePicker = () => {
    fileInput.value.pickFiles();
  };

  return {
    state,
    fileInput,
    setHeight,
    handleClickDepositMode,
    handleUserChange,
    handleChooseFile,
    handleClickBack,
    handleSubmitDeposit,
    optionsDate,
    openFilePicker,
  };
}
