<template>
  <q-layout id="layout" view="hHh lpR fFf" class="bg-background">
    <q-page-container>
      <router-view></router-view>
    </q-page-container>

    <q-dialog
      v-model="loading.isLoading"
      transition-show="scale"
      transition-hide="scale"
    >
      <q-card class="font-regular rounded full-width bg-white">
        <q-card-section class="flex items-center justify-center q-px-lg">
          <div>
            <q-spinner color="d-green" size="3em" />
          </div>
          <div class="q-pl-md font-medium">
            <span style="font-size: 1.5rem">{{ loading.text }}</span>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog
      @hide="handleClickDialogHide(alert.isAction)"
      v-model="alert.isAlert"
      :persistent="alert.isPersistent"
    >
      <q-card
        class="font-regular rounded"
        :class="[
          alert.alertBackground,
          alert.isAction === true ? 'full-width' : '',
        ]"
      >
        <q-card-section
          class="flex items-center justify-center q-px-lg"
          :class="[
            alert.contentColor,
            alert.isAction === true ? 'q-pt-xl' : '',
          ]"
        >
          <!-- <q-btn
            v-if="alert.isCloseBtn === true"
            @click="handleClickIconClose()"
            class="absolute-top-right"
            style="font-size: 18px"
            icon="close"
            color="grey-4b"
            flat
            round
            dense
            v-close-popup
          /> -->
          <div v-if="alert.isIcon">
            <q-icon :name="alert.iconName" size="md" />
          </div>
          <div v-html="alert.alertContent"></div>
        </q-card-section>

        <q-card-actions v-if="alert.isAction === true" align="right">
          <q-btn
            style="font-size: 18px"
            @click="handleClickGoBack"
            label="กลับหน้าหลัก"
            color="white"
            text-color="black"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-layout>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from "vue";

import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      access_token: "",
      loading: computed(() => store.state.authModule.loading),
      alert: computed(() => store.state.authModule.alert),
    });

    onMounted(async () => {
      addMeta();
    });

    const addMeta = () => {
      const meta = document.createElement("meta");
      meta.setAttribute("name", "viewport");
      meta.setAttribute(
        "content",
        "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      );
      document.head.appendChild(meta);
    };

    const handleClickGoBack = () => {
      router.replace({ name: "homePage" });
    };

    const handleClickDialogHide = (home) => {
      console.log("hide");
      if (home === true) {
        router.replace({ name: "homePage" });
      }
    };

    return { ...toRefs(state), handleClickGoBack, handleClickDialogHide };
  },
};
</script>
