import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/home/Home.vue";
import Login from "../views/login/Login.vue";
import Deposit from "../views/deposit/Deposit.vue";
import Withdraw from "../views/withdraw/Withdraw.vue";
import DepositBank from "../views/depositBank/DepositBank.vue";
import Report from "../views/report/Report.vue";
import Error from "../views/Error.vue";
import Loading from "../views/loading/Loading.vue";

const routes = [
  {
    path: "/",
    name: "loadingPage",
    component: Loading,
  },
  {
    path: "/home",
    name: "homePage",
    component: Home,
  },
  {
    path: "/deposit",
    name: "depositPage",
    component: Deposit,
  },
  {
    path: "/withdraw",
    name: "withdrawPage",
    component: Withdraw,
  },
  {
    path: "/depositBank",
    name: "depositBankPage",
    component: DepositBank,
  },
  {
    path: "/report",
    name: "reportPage",
    component: Report,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/error",
    name: "errorPage",
    component: Error,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
