<template>
  <q-page class="q-gutter-y-md q-mx-md" :style-fn="setHeight">
    <div ref="input" class="q-pt-md">
      <q-select
        @update:model-value="handleUserChange"
        filled
        v-model="user"
        color="grey"
        :options="userOptions"
        label="เลือก User"
        emit-value
        map-options
        :behavior="'menu'"
      />
    </div>
    <div ref="sep"><q-separator /></div>
    <div class="bg-white q-pa-md" :style="{ height: setChatHeight() }">
      <q-scroll-area class="full-height font-regular">
        <input
          type="text"
          id="textCopy"
          class="absolute-top-right"
          style="opacity: 0"
          readonly
        />
        <!-- <div class="q-gutter-y-md">
          <div class="bank-item q-gutter-x-sm q-pa-sm q-ml-none">
            <div class="bank-icon bay"></div>
            <div class="q-pl-sm">
              <div>ฟหก</div>
              <div>ปปป</div>
              <div>
                999-999-9999
                <q-icon
                  @click="handleClickCopyAcc('999-999-9999')"
                  name="content_copy"
                  color="n-green"
                  size="xs"
                  style="transform: translateY(-15%)"
                ></q-icon>
              </div>
            </div>
          </div>
        </div> -->
        <div class="q-gutter-y-md">
          <div
            v-show="bankLists.length > 0"
            v-for="(bankList, index) in bankLists"
            :key="index"
            class="bank-item q-gutter-x-sm q-pa-sm q-ml-none"
          >
            <div
              v-show="bankList.isCopy === true"
              class="absolute-center text-white q-py-sm q-px-md rounded"
              style="background: rgba(86, 86, 86, 0.8); z-index: 3"
            >
              คัดลอกแล้ว
            </div>
            <div class="bank-icon" :class="bankList.bankCode"></div>
            <div class="q-pl-sm">
              <div>{{ bankList.bankName }}</div>
              <div>{{ bankList.accName }}</div>
              <div>
                {{ bankList.accNumber }}
                <q-icon
                  @click="handleClickCopyAcc(bankList.accNumber, index)"
                  name="content_copy"
                  color="n-green"
                  size="xs"
                  style="transform: translateY(-15%)"
                ></q-icon>
              </div>
            </div>
          </div>
        </div>

        <div
          v-show="bankLists.length <= 0"
          class="absolute-center text-grey-6f text-center"
          style="font-size: 18px"
          v-html="error"
        ></div>
      </q-scroll-area>
    </div>
    <div class="flex justify-end font-medium" ref="btn">
      <q-btn
        style="font-size: 18px"
        @click="handleClickGoBack"
        label="กลับหน้าหลัก"
        color="white"
        text-color="black"
        v-close-popup
      />
    </div>
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import { useDepositBank } from "./composable/use-depositBank";

export default {
  setup() {
    const {
      state,
      input,
      sep,
      btn,
      setHeight,
      handleUserChange,
      setChatHeight,
      handleClickCopyAcc,
      handleClickGoBack,
    } = useDepositBank();

    return {
      ...toRefs(state),
      input,
      sep,
      btn,
      setHeight,
      handleUserChange,
      setChatHeight,
      handleClickCopyAcc,
      handleClickGoBack,
    };
  },
};
</script>

<style lang="scss" scoped></style>
