const transactionModule = {
  namespaced: true,
  state: {
    user: [],
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
    },
  },
  actions: {
    setUser(context, data) {
      context.commit("SET_USER_DATA", data);
    },
  },
};

export default transactionModule;
