import { utilily } from "@/utilities/utility";
import { computed, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export function useHome() {
  const router = useRouter();
  const store = useStore();
  const { setHeight } = utilily();
  const state = reactive({
    access_token: "",
    debug: computed(() => store.state.authModule.debug),
    debug2: "",
  });

  const handleClickDeposit = () => {
    router.push({ name: "depositPage" });
  };

  const handleClickWithdraw = () => {
    router.push({ name: "withdrawPage" });
  };

  const handleClicBankList = () => {
    router.push({ name: "depositBankPage" });
  };

  const handleClickReport = () => {
    router.push({ name: "reportPage" });
  };

  return {
    state,
    setHeight,
    handleClickDeposit,
    handleClickWithdraw,
    handleClicBankList,
    handleClickReport,
  };
}
