<template>
  <q-page class="q-mx-md font-regular" :style-fn="setHeight">
    <div v-if="isSelectedMode === false" class="q-gutter-y-md">
      <div class="row q-pt-md">
        <div @click="handleClickDepositMode(false)" class="home-item">
          <div
            class="flex items-center justify-center font-medium q-mb-sm"
            style="font-size: 1.25rem"
          >
            แจ้งฝากด้วยสลิป
            <q-badge color="l-red" class="q-ml-sm">NEW</q-badge>
          </div>
          <img
            src="@/assets/images/deposit/deposit_slip.svg"
            alt=""
            class="full-width"
          />
        </div>
      </div>
      <div>
        <div @click="handleClickDepositMode(true)" class="home-item">
          <div
            class="text-center font-medium q-mb-sm"
            style="font-size: 1.25rem"
          >
            แจ้งฝากปกติ
          </div>
          <img
            src="@/assets/images/deposit/deposit_normal.svg"
            alt=""
            class="full-width"
          />
        </div>
      </div>
    </div>

    <q-form
      v-else
      @submit="
        handleSubmitDeposit(
          isNormalMode === true ? true : image_url !== null ? true : false
        )
      "
      class="q-gutter-y-md"
    >
      <div class="q-pt-md">
        <q-select
          @update:model-value="handleUserChange"
          filled
          v-model="user"
          bg-color="white"
          color="grey"
          :options="userOptions"
          label="เลือก User"
          emit-value
          map-options
          :behavior="'menu'"
          :rules="[selectRule]"
        />
      </div>
      <div v-if="isNormalMode === true" class="q-mt-none">
        <q-select
          filled
          v-model="userBank"
          :bg-color="userBankOptions.length <= 0 ? 'grey-95' : 'white'"
          color="grey"
          :options="userBankOptions"
          label="โอนจากบัญชี"
          emit-value
          map-options
          :disable="userBankOptions.length <= 0 ? true : false"
          :behavior="'menu'"
          :rules="[selectRule]"
        >
          <template v-slot:selected-item="scope">
            <div class="row full-width">
              <div
                v-if="userBankOptions.length <= 0"
                class=""
                style="width: 10%"
              >
                <div
                  class="bank-icon full-height full-width"
                  :class="scope.opt.icon"
                ></div>
              </div>
              <div class="" style="width: 90%">{{ scope.opt.label }}</div>
            </div>
          </template>
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section avatar>
                <div
                  class="bank-icon full-height full-width"
                  :class="scope.opt.icon"
                ></div>
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-black">{{
                  scope.opt.label
                }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
      <div v-if="isNormalMode === true" class="q-mt-none">
        <q-select
          filled
          v-model="bank"
          :bg-color="bankOptions.length <= 0 ? 'grey-95' : 'white'"
          color="grey"
          :options="bankOptions"
          label="โอนเข้าบัญชี"
          emit-value
          map-options
          :disable="bankOptions.length <= 0 ? true : false"
          :behavior="'menu'"
          :rules="[selectRule]"
        >
          <template v-slot:selected-item="scope">
            <div class="row full-width">
              <div v-if="bankOptions.length <= 0" class="" style="width: 10%">
                <div
                  class="bank-icon full-height full-width"
                  :class="scope.opt.icon"
                ></div>
              </div>
              <div class="" style="width: 90%">{{ scope.opt.label }}</div>
            </div>
          </template>
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps">
              <q-item-section avatar>
                <div
                  class="bank-icon full-height full-width"
                  :class="scope.opt.icon"
                ></div>
              </q-item-section>
              <q-item-section>
                <q-item-label class="text-black">{{
                  scope.opt.label
                }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
      </div>
      <div v-if="isNormalMode === true" class="q-mt-none">
        <q-input
          filled
          v-model="amount"
          type="number"
          bg-color="white"
          color="black"
          label="ยอดเงิน"
          :rules="[numberRule]"
        />
      </div>
      <div class="q-mt-none">
        <q-file
          ref="fileInput"
          @update:model-value="(val) => handleChooseFile(val)"
          bg-color="white"
          color="black"
          filled
          v-model="imageFile"
          label="อัพโหลดสลิป"
          accept="image/*"
          :rules="[isNormalMode === true ? '' : fileRule]"
        >
          <template v-slot:append>
            <q-icon @click="openFilePicker" name="file_upload" color="green" />
          </template>
        </q-file>
      </div>
      <div v-if="isNormalMode === true" class="row q-col-gutter-x-md q-mt-none">
        <div class="col-8">
          <q-input
            bg-color="white"
            color="black"
            filled
            v-model="date"
            mask="date"
            :rules="[dateRule]"
          >
            <template v-slot:prepend>
              <q-icon name="event" color="green" class="cursor-pointer">
                <q-popup-proxy
                  cover
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-date v-model="date" color="green" :options="optionsDate">
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-date>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
        <div class="col-4">
          <q-input
            bg-color="white"
            color="black"
            filled
            v-model="time"
            mask="time"
            :rules="[timeRule]"
          >
            <template v-slot:append>
              <q-icon name="access_time" color="green" class="cursor-pointer">
                <q-popup-proxy
                  cover
                  transition-show="scale"
                  transition-hide="scale"
                >
                  <q-time v-model="time" color="green" format24h>
                    <div class="row items-center justify-end">
                      <q-btn v-close-popup label="Close" color="primary" flat />
                    </div>
                  </q-time>
                </q-popup-proxy>
              </q-icon>
            </template>
          </q-input>
        </div>
      </div>
      <div class="row q-col-gutter-x-md font-medium">
        <div class="col-6">
          <q-btn
            @click="handleClickBack"
            class="full-width"
            color="white"
            text-color="black"
            label="กลับ"
          />
        </div>
        <div class="col-6">
          <q-btn
            type="submit"
            class="full-width"
            :color="
              isNormalMode === true
                ? user !== '' &&
                  userBank !== '' &&
                  bank !== '' &&
                  amount !== '' &&
                  date !== '' &&
                  time !== ''
                  ? 'gd-green'
                  : 'grey-95'
                : user !== '' && image_url !== null
                ? 'gd-green'
                : 'grey-95'
            "
            text-color="white"
            label="แจ้งโอนเงิน"
          />
        </div>
      </div>
    </q-form>
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import { useDeposit } from "./composable/use-deposit";
import { utilily } from "../../utilities/utility";

export default {
  setup() {
    const {
      state,
      fileInput,
      setHeight,
      handleClickDepositMode,
      handleUserChange,
      handleChooseFile,
      handleClickBack,
      handleSubmitDeposit,
      optionsDate,
      openFilePicker,
    } = useDeposit();

    const { numberRule, selectRule, dateRule, timeRule, fileRule } = utilily();

    return {
      ...toRefs(state),
      fileInput,
      setHeight,
      handleClickDepositMode,
      handleUserChange,
      handleChooseFile,
      handleClickBack,
      handleSubmitDeposit,
      optionsDate,
      openFilePicker,
      numberRule,
      selectRule,
      dateRule,
      timeRule,
      fileRule,
    };
  },
};
</script>

<style lang="scss" scoped></style>
