<template>
  <q-page class="q-gutter-y-md q-mx-md font-regular" :style-fn="setHeight">
    <q-dialog
      v-model="isComplete"
      transition-show="scale"
      transition-hide="scale"
    >
      <q-card
        class="font-regular rounded"
        style="background-color: #565656e5; width: fit-content"
      >
        <q-card-section class="flex items-center justify-center q-px-lg">
          <div class="font-medium text-white">
            <span style="font-size: 1.5rem">อัพเดตแล้ว</span>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <div class="q-pt-md">
      <q-tabs
        v-model="tab"
        dense
        class="tabHeader text-grey-66 round-top"
        active-color="black"
        indicator-color="transparent"
        align="justify"
      >
        <q-tab
          @click="getDepositList"
          class="round-top"
          :class="
            tab !== 'deposit'
              ? 'bg-grey-f3 text-grey-87 font-regular'
              : ' font-medium'
          "
          name="deposit"
          label="ฝาก"
        />
        <q-tab
          @click="getWithdrawList"
          class="round-top"
          :class="
            tab !== 'withdraw'
              ? 'bg-grey-f3 text-grey-87 font-regular'
              : 'font-medium'
          "
          name="withdraw"
          label="ถอน"
        />
      </q-tabs>
      <q-tab-panels v-model="tab" animated class="round-bottom">
        <q-tab-panel name="deposit" class="q-pa-sm">
          <div v-if="depositLists.length <= 0" class="text-center q-pa-lg">
            ไม่มีรายการฝากเงิน
          </div>
          <div
            @click="handleClickRefresh"
            class="row justify-end items-center q-mb-sm"
            style="color: #7e7e7e"
          >
            <q-icon name="refresh" /> &nbsp;
            <span>ข้อมูล ณ เวลา {{ depositUpdateTime }} น.</span>
          </div>
          <div
            v-for="(depositList, index) in depositLists"
            :key="index"
            class="detail-financial-item row q-col-gutter-x-sm q-ml-none q-mb-sm cursor-pointer"
          >
            <div class="col-7 q-pl-none font-medium">
              <div>
                <span>ID : {{ depositList.code }}</span>
              </div>
              <div>
                <span>User : {{ depositList.more_data.user }}</span>
              </div>
              <div>
                <span>จำนวน : {{ numberWithCommas(depositList.amount) }}฿</span>
              </div>
            </div>
            <div class="col-5 font-semibold self-center">
              <q-badge
                :color="
                  depositList.status >= 900
                    ? 'n-green'
                    : depositList.status > 300
                    ? 'l-red'
                    : depositList.status >= 100
                    ? 'd-orange'
                    : 'l-red'
                "
                class="q-pa-sm full-width justify-center q-mt-xs"
              >
                {{
                  depositList.status >= 900
                    ? "เรียบร้อย"
                    : depositList.status > 300
                    ? "ยกเลิก"
                    : depositList.status >= 100
                    ? "รอดำเนินการ"
                    : "Unknown"
                }}
              </q-badge>
              <div>
                <span
                  class="flex justify-end font-regular text-grey-87"
                  style="margin-top: 10px"
                >
                  {{
                    formatTimestamp(
                      depositList.more_data.transaction_time * 1000
                    )
                  }}</span
                >
              </div>
            </div>
          </div>
        </q-tab-panel>

        <q-tab-panel name="withdraw" class="q-pa-sm">
          <div v-if="withdrawLists.length <= 0" class="text-center q-pa-lg">
            ไม่มีรายการถอนเงิน
          </div>
          <div
            @click="handleClickRefresh"
            class="row justify-end items-center q-mb-sm"
            style="color: #7e7e7e"
          >
            <q-icon name="refresh" /> &nbsp;
            <span>ข้อมูล ณ เวลา {{ withdrawUpdateTime }} น.</span>
          </div>
          <div
            v-for="(withdrawList, index) in withdrawLists"
            :key="index"
            class="detail-financial-item red row q-col-gutter-x-sm q-ml-none q-mb-sm cursor-pointer"
          >
            <div class="col-7 q-pl-none font-medium">
              <div>
                <span>ID : {{ withdrawList.code }}</span>
              </div>
              <div>
                <span>User : {{ withdrawList.more_data.user }}</span>
              </div>
              <div>
                <span
                  >จำนวน : {{ numberWithCommas(withdrawList.amount) }}฿</span
                >
              </div>
            </div>
            <div class="col-5 font-semibold self-center">
              <q-badge
                :color="
                  withdrawList.status >= 900
                    ? 'n-green'
                    : withdrawList.status > 300
                    ? 'l-red'
                    : withdrawList.status >= 100
                    ? 'd-orange'
                    : 'l-red'
                "
                class="q-pa-sm full-width justify-center q-mt-xs"
              >
                {{
                  withdrawList.status >= 900
                    ? "เรียบร้อย"
                    : withdrawList.status > 300
                    ? "ยกเลิก"
                    : withdrawList.status >= 100
                    ? "รอดำเนินการ"
                    : "Unknown"
                }}
              </q-badge>
              <div>
                <span
                  class="flex justify-end font-regular text-grey-87"
                  style="margin-top: 10px"
                >
                  {{ formatTimestamp(withdrawList.updated_at * 1000) }}</span
                >
              </div>
            </div>
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </div>
    <div class="flex justify-end">
      <q-btn
        @click="handleClickRefresh"
        class="q-mr-sm"
        color="white"
        text-color="black"
        icon="refresh"
        label="รีเฟรช"
      />
      <q-btn
        @click="handleClickBackHome"
        class=""
        color="white"
        text-color="black"
        label="กลับสู่หน้าหลัก"
      />
    </div>
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import { useReport } from "./composable/use-report";

export default {
  setup() {
    const {
      state,
      setHeight,
      handleClickRefresh,
      handleClickBackHome,
      getDepositList,
      getWithdrawList,
      numberWithCommas,
      formatTimestamp,
    } = useReport();

    return {
      ...toRefs(state),
      setHeight,
      handleClickRefresh,
      handleClickBackHome,
      getDepositList,
      getWithdrawList,
      numberWithCommas,
      formatTimestamp,
    };
  },
};
</script>

<style lang="scss" scoped>
.tabHeader {
  background-color: white;
}
.detail-financial-item {
  padding: 0.25rem 0.5rem;
  background-color: $w-green;
  border-radius: 0.5rem;
  &.red {
    background-color: $w-red;
  }
}
</style>
