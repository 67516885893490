<template>
  <q-page class="q-gutter-y-lg q-mx-md" :style-fn="setHeight">
    <div class="logo-sector flex justify-center">
      <div class="login-logo"></div>
    </div>
    <div class="text-center font-semibold text-h6">ลงชื่อเข้าใช้</div>
    <div class="flex justify-center font-regular">
      <q-input
        class="full-width"
        v-model="OtpText"
        label="กรุณาใส่รหัส OTP"
        hint="ลูกค้าสามารถขอรหัสจากทางแอดมินได้เลยค่ะ 24 ชม."
        outlined
      />
    </div>
    <div class="font-medium text-center">
      <q-btn
        @click="handleLoginSubmit"
        class="full-width"
        :color="OtpText === '' ? 'grey-95' : 'gd-green'"
        text-color="white"
        label="ลงชื่อเข้าใช้"
      />
    </div>
  </q-page>
</template>

<script>
import { toRefs } from "vue";
import { useLogin } from "./composable/use-login";

export default {
  setup() {
    const { state, setHeight, handleLoginSubmit } = useLogin();

    return {
      ...toRefs(state),
      setHeight,
      handleLoginSubmit,
    };
  },
};
</script>

<style lang="scss" scoped></style>
