import { computed, onMounted, reactive, watch } from "vue";
import { utilily } from "@/utilities/utility";
import { useStore } from "vuex";
import {
  getMemberBankList,
  getUser,
  postTransaction,
} from "../../../services/transaction-service";
import { useRouter } from "vue-router";

export function useWithdraw() {
  const { setHeight } = utilily();
  const store = useStore();
  const router = useRouter();
  const state = reactive({
    user: "",
    userOptions: [],
    bank: "",
    bankOptions: [],
    amount: "",
    userLists: computed(() => store.state.transactionModule.user),
    isClickCloseBtn: computed(() => store.state.authModule.isClickCloseBtn),
    debug: "",
    debug2: "",
  });

  onMounted(async () => {
    store.dispatch("authModule/setIsloading", {
      isLoading: true,
      text: "กำลังโหลดข้อมูล",
    });
    await getUserData();
    store.dispatch("authModule/setIsloading", {
      isLoading: false,
      text: "",
    });
  });

  watch(
    () => state.isClickCloseBtn,
    (isClickCloseBtn) => {
      if (isClickCloseBtn === true) {
        state.user = "";
        state.bank = "";
        state.amount = "";
      }
    }
  );

  const getUserData = async () => {
    const response = await getUser();
    state.debug = response;
    if (response.status === "success") {
      store.dispatch("transactionModule/setUser", response.data);
      state.userLists.forEach((element) => {
        state.userOptions.push({ label: element.user, value: element.id });
      });
      if (state.userOptions.length === 1) {
        getMemberBankListData(state.userOptions[0].value);
        state.user = state.userOptions[0].value;
      }
    } else if (response.status === "http_error") {
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: true,
        isAction: false,
        isPersistent: false,
        iconName: "cancel",
        alertContent: `<span class="font-medium text-h6 q-pl-sm">${response.message}</span>`,
        contentColor: "text-d-red",
        alertBackground: "bg-white",
        isRedirect: true,
      });
    } else {
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: response.data?.descHtml !== undefined ? false : true,
        isAction: false,
        isPersistent: false,
        iconName: "cancel",
        alertContent:
          response.data?.descHtml !== undefined
            ? `${response.data.descHtml}`
            : `<div class='q-pl-sm'><div class="font-medium text-h6">ไม่พบ ID ในระบบ</div><div>กรุณาติดต่อแอดมินเพื่อทำการแก้ไข</div></div>`,
        contentColor: "text-d-red",
        alertBackground: "bg-white",
        isRedirect: false,
      });
    }
  };

  const getMemberBankListData = async (id) => {
    state.bank = "กำลังโหลดข้อมูล";
    const response = await getMemberBankList(id);
    state.bank = "";
    state.debug = response;
    state.bankOptions = [];
    if (response.status === "success") {
      response.data.forEach((element) => {
        state.bankOptions.push({
          label: element.bankName + " " + element.accNumber,
          icon: element.bankCode,
          value: element.id,
        });
      });
      if (state.bankOptions.length === 1) {
        state.bank = state.bankOptions[0].value;
      }
    } else if (response.status === "http_error") {
      state.bank = "ไม่พบบัญชีธนาคารในระบบ";
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: true,
        isAction: false,
        isPersistent: false,
        iconName: "cancel",
        alertContent: `<span class="font-medium text-h6 q-pl-sm">${response.message}</span>`,
        contentColor: "text-d-red",
        alertBackground: "bg-white",
        isRedirect: true,
      });
    } else {
      state.bank = "ไม่พบบัญชีธนาคารในระบบ";
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: response.data?.descHtml !== undefined ? false : true,
        isAction: false,
        isPersistent: false,
        iconName: "cancel",
        alertContent:
          response.data?.descHtml !== undefined
            ? `${response.data.descHtml}`
            : `<div class='q-pl-sm'><div class="font-medium text-h6">ไม่พบบัญชีธนาคารในระบบ</div><div>กรุณาติดต่อแอดมินเพื่อทำการแก้ไข</div></div>`,
        contentColor: "text-d-red",
        alertBackground: "bg-white",
        isRedirect: false,
      });
    }
  };

  const handleUserChange = (value) => {
    state.user = value;
    getMemberBankListData(value);
  };

  const handleClickBack = () => {
    router.push({ name: "homePage" });
  };

  const handleSubmitWithdraw = async () => {
    store.dispatch("authModule/setIsloading", {
      isLoading: true,
      text: "กำลังส่งข้อมูล",
    });
    const userById = state.userLists.find((obj) => obj.id === state.user);
    const data = {
      type: "400",
      user: userById.user,
      bank_acc: state.bank,
      amount: state.amount,
      note: "แจ้งถอน",
    };
    state.debug = data;
    const response = await postTransaction(data);
    state.debug2 = response;
    store.dispatch("authModule/setIsloading", {
      isLoading: false,
      text: "",
    });
    if (response.status === "success") {
      store.dispatch("authModule/setIsClickCloseBtn", false);
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: true,
        isAction: true,
        isPersistent: false,
        iconName: "check_circle",
        alertContent: `<span class="font-medium text-h6 q-pl-sm" style="font-size:1.5rem">แจ้งถอนเงินสำเร็จ</span>`,
        contentColor: "text-d-green",
        alertBackground: "bg-l-green",
        isRedirect: true,
      });
    } else if (response.status === "http_error") {
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: true,
        isAction: false,
        isPersistent: false,
        iconName: "cancel",
        alertContent: `<span class="font-medium text-h6 q-pl-sm">${response.message}</span>`,
        contentColor: "text-d-red",
        alertBackground: "bg-white",
        isRedirect: true,
      });
    } else {
      store.dispatch("authModule/setAlert", {
        isAlert: true,
        isIcon: response.data?.descHtml !== undefined ? false : true,
        isAction: false,
        isPersistent: false,
        iconName: "cancel",
        alertContent:
          response.data?.descHtml !== undefined
            ? `${response.data.descHtml}`
            : `<span class="font-medium text-h6 q-pl-sm">${response.message}</span>`,
        contentColor: "text-d-red",
        alertBackground: "bg-white",
        isRedirect: true,
      });
    }
  };

  return {
    state,
    setHeight,
    handleUserChange,
    handleClickBack,
    handleSubmitWithdraw,
  };
}
